import {faExclamationTriangle} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React from 'react';
import Style from 'styles/components/comachicart/maintenance.module.scss';

const Maintenance = ({data}) => {
  const contentHtml = {__html: data.content.replace(/\r\n/g, '<br/>')};
    return (
        <section className={Style.maintenance}>
          <h2 className={classNames(Style.catch)}>
            <span>{data.title}</span>
          </h2>
          <p
            className={classNames(Style.maintenance__notice, Style.mt40, Style.mb40)}
            dangerouslySetInnerHTML={
    contentHtml}
          />
        </section>
      );
};
export default Maintenance;
